<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card class="text-center">
      <q-card-section class="text-h6 q-pb-none">
        {{ $t(title) }}
      </q-card-section>

      <q-card-section class="f-s-14 q-pb-none">
        {{ $t('Remaining') + ': ' }}
        <strong>{{ attempts }}</strong>
      </q-card-section>

      <q-card-section class="q-pb-none">
        <pincode
          @input="handleInput"
          @submit="handlePincode"
        />
      </q-card-section>

      <q-card-section @click="handlePincode(value)">
        <button class="btn btn-success btn-sm">
          {{ $t('OK') }}
        </button>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Services
import { UserService } from '../../services'

// Components
import Pincode from './Pincode'

export default {
  name: 'PincodeModal',
  components: {
    Pincode
  },
  props: {
    action: {
      type: String,
      default () {
        return 'check'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      value: '',
      attempts: 3,
      userdata: JSON.parse(localStorage.getItem('userData'))
    }
  },
  computed: {
    title () {
      return this.userdata && this.userdata.eav && this.userdata.eav['users-pincode']
        ? 'Please write your pincode'
        : 'It is required to set your pincode'
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    resolve () {},
    reject () {},
    showPromise () {
      this.show()

      localStorage.setItem('isLoginWithToken', true)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    show () {
      this.userdata = JSON.parse(localStorage.getItem('userData'))
      this.isOpen = true
    },
    hide () {
      this.isOpen = false
    },
    check (pincode) {
      if (this.userdata && this.userdata.eav && this.userdata.eav['users-pincode']) {
        if (pincode === this.userdata.eav['users-pincode']) {
          localStorage.setItem('isLoginWithToken', false)
          this.isOpen = false
          this.resolve(true)
        } else {
          this.attempts--
          this.addErrorNotification('Sorry, pincode is wrong!')

          if (this.attempts === 0) {
            this.handleUnsucessfulLoging()
            return
          }

          setTimeout(() => {
            this.isOpen = true
          })
        }

        return
      }

      this.save(pincode)
    },
    handlePincode (pincode) {
      if (this[this.action]) {
        return this[this.action](pincode)
      }

      this.addErrorNotification('Action is not found')
    },
    save (pincode) {
      if (pincode.length < 4) {
        this.addErrorNotification('Minimum pincode length is 4 characters!')

        setTimeout(() => {
          this.isOpen = true
        })

        return
      }

      this.$service.user.save({ eav: { 'users-pincode': pincode } }, this.userdata.id)
        .then(user => {
          this.resolve(true)
          localStorage.setItem('userData', JSON.stringify(user))
          localStorage.setItem('isLoginWithToken', false)
        })
    },
    handleUnsucessfulLoging () {
      this.resolve(false)
      UserService.logout()
    },
    handleInput (value) {
      this.value = value
    }
  }
}
</script>
