<template>
  <form @submit="handleSubmit">
    <div class="d-flex justify-content-center align-content-center mb-4">
      <input
        v-for="cell in cells"
        :ref="`${baseRefName}_${cell.key}`"
        :key="cell.key"
        :model-value="cell.value"
        class="form-control mr-2"
        type="number"
        @update:model-value="handleInput($event, cell)"
        @focus="onFocus(cell)"
      >
    </div>

    <div>
      <div class="row mb-2">
        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(1)"
          >
            1
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(2)"
          >
            2
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(3)"
          >
            3
          </button>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(4)"
          >
            4
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(5)"
          >
            5
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(6)"
          >
            6
          </button>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(7)"
          >
            7
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(8)"
          >
            8
          </button>
        </div>

        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(9)"
          >
            9
          </button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-4">
          <button
            type="button"
            class="btn btn-white btn-block"
            @click="handleValue(0)"
          >
            0
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  emits: ['submit', 'input'],
  props: {
    length: {
      type: Number,
      default () {
        return 4
      }
    }
  },
  data () {
    return {
      baseRefName: 'pincode-input',
      cells: [],
      cell: null
    }
  },
  watch: {
    length () {
      this.reset()
    }
  },
  mounted () {
    this.reset()
  },
  methods: {
    onFocus (cell) {
      this.cell = cell
    },
    getValue () {
      return this.cells.reduce((acc, { value }) => acc + value, '')
    },
    handleSubmit (e) {
      if (e) {
        e.preventDefault()
      }

      const value = this.getValue()

      this.$emit('submit', value)
    },
    handleInput (e, cell) {
      this.cell = cell
      this.handleValue(e, cell)
    },
    handleValue (value) {
      const val = `${value}`
      this.cell.value = val[val.length - 1]

      const index = this.cells.findIndex(c => c.key === this.cell.key)
      const currentRefName = `${this.baseRefName}_${this.cells[index].key}`
      this.$refs[currentRefName][0].value = this.cell.value

      if (index < this.cells.length - 1) {
        const refName = `${this.baseRefName}_${this.cells[index + 1].key}`
        this.$refs[refName][0].focus()
      }

      this.$emit('input', this.getValue())
    },
    createCells (length) {
      const cells = []

      for (let i = 0; i < length; i++) {
        cells.push({ value: '', key: uuidv4() })
      }

      return cells
    },
    reset () {
      this.cells = this.createCells(this.length)
      this.cell = this.cells[0]
    }
  }
}
</script>
